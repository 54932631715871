/*

====================================

Para compilar observando por cambios 
ejecutar (desde raiz del proyecto):

  $ npm run dev

====================================

*/

@import "../../../node_modules/bootstrap/scss/functions";

// Import custom variables
@import "site-variables";
@import "bootstrap-variables";

// Import Bootstrap for Sass
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Our Evil Stylesheets
@import "layout";
@import "dashboard";
